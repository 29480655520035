* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "poppins";
  transition: all ease-in-out 0.4s;
}
/* general */
.w-70 {
  width: 70%;
}
.mt-max {
  margin-top: 120px;
}
span {
  font-size: 1.2em;
}
/* NavBar */
.logo {
  width: 90px;
  height: 80px;
}
.navbar .not-home .nav-link {
  color: #2d2d2a !important;
}
.navbar {
  border-bottom: 0.1px solid transparent;
}
@media screen and (max-width: 991px) {
  .navbar{
    background-color: #fff !important;
    border-bottom: 0.1px solid rgba(0, 0, 0, 0.1);
  }
  .home{
    padding-top: 5rem;
  }
}
.navBg {
  background-color: #fff !important;
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.1);
}
.navBg .nav-link {
  color: #2d2d2a !important;
}
.nav-link {
  font-weight: bold;
  color: #fff !important;
}
.navbar .bi-list,
.navbar .bi-x {
  font-size: 2rem;
}
@media (min-width: 993px) {
  .navbar-collapse {
    width: 80%;
    margin-left: auto;
    flex-grow: 0;
    flex-basis: none;
  }
  .navbar-collapse .navbar-nav {
    margin: 0 0 0 auto;
  }
}
@media (max-width: 992px) {
  .navbar i {
    color: black;
  }
  .nav-link {
    font-weight: bold;
    color: #2d2d2a !important;
  }
}
.navbar-dark .navbar-toggler {
  color: transparent;
  border-color: transparent;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.gridCol{
  justify-content: space-evenly;
}
.gridCenter{
  justify-content: center;
}

/* Header */
.header {
  height: 110vh;
  margin-top: -10px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: url("../images/ecell-bg.svg") center center;
}
@media (max-width: 768px) {
  .header {
    height: 100%;
  }
  .header .w-70 {
    margin: 0.4rem 0;
    width: 100%;
  }
}
.header .container {
  height: 100%;
}
.header {
  color: #1a5935;
}
.home-img {
  animation: up-down 1.4s ease-in-out infinite alternate-reverse both;
  width: 80%;
  margin: auto;
}
.my-btn {
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 10px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  background: #32f18f;
  padding: 11px 22px 11px 22px;
  text-decoration: none;
  border: none;
}

.my-btn:hover {
  background: #26a362;
  text-decoration: none;
}
/* Blogs */
/* .owl-item,
.owl-stage {
  box-shadow: 15px 16px 55px -18px rgba(10, 34, 20, 0.79) !important;
  -webkit-box-shadow: 15px 16px 55px -18px rgba(10, 34, 20, 0.79);
  -moz-box-shadow: 15px 16px 55px -18px rgba(10, 34, 20, 0.79);
} */
.blog-details {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.blog-details img {
  border-radius: 10px 10px 0 0;
  height: 280px;
  object-fit: cover;
  width: 100%;
}
.blog-details div {
  background-color: #0a221405;
  border-radius: 0 0 15px 15px;
}
.owl-carousel .owl-item {
  border-radius: 10px;
  width: 340px !important;
  margin: 0 1.2rem;
}
.owl-carousel .owl-stage {
  display: flex;
  justify-content: center;
}
.owl-carousel .owl-nav {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  background-color: #26a362;
  color: white;
  border-radius: 55px;
  border: none;
  font-weight: bold;
  margin: 0 1rem;
  padding: 15px 25px !important;
  position: relative;
}
.owl-carousel button.owl-dot {
  display: none !important;
}
.my-btn-2 {
  background-color: #32f18f;
  color: white;
  border: none;
  border-radius: 5px;
}
.my-btn-2:hover {
  background-color: #26a362;
}
.item .imgContainer {
  overflow: hidden;
  border-radius: 15px;
  background: #0a221405;
}
.item img {
  transition: all ease-in-out 0.6s;
  border-radius: 15px 15px 0 0;
  background: #0a221405;
}
.item .card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 48px;
  max-height: 48px;
}
/* Footer */
.footer {
  background-color: rgb(32, 32, 32);
  color: #fff;
  position: relative;
  width: 100%;
  top: auto;
  bottom: 0;
}
.footer li {
  list-style: none;
  opacity: 0.8;
}
.footer ul {
  height: 10vh;
}
.footer th {
  font-size: 20px;
}
.footer a {
  text-decoration: none;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}
.copyrights {
  border-top: #0a2214 1px solid;
}

/* Contact */
.form-control,
textarea {
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px !important;
  border: 1px rgba(10, 34, 20, 0.5) solid !important;
}
textarea {
  height: 300px;
  padding: 20px 20px !important;
  outline: none;
}
@keyframes up-down {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(15px);
  }
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(15px);
  }
}
.footer a:hover {
  color: #fff;
  opacity: 1;
}
.footer span {
  color: #32f18f;
  opacity: 0.9;
}
.event-container h1 {
  margin: 1.5rem 0;
}
/* debugging of owl */
@media (min-width: 576px) {
  .Blogs .container {
    max-width: 100%;
  }
  .owl-stage {
    justify-content: space-evenly;
  }
}
.item:hover img {
  transform: scale(1.2);
}

@media (max-width: 992px) {
  .img-container {
    flex-direction: column-reverse !important;
    background: none !important;
  }
  .event-title {
    display: none;
  }
  .sic {
    right: 0px !important;
  }
}

@media (min-width: 992px) {
  .secondary-title {
    display: none;
  }
}
